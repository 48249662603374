<template>
  <div class="page-list-content admin_container_default">
    <div class="page-list-table">
      <div class="mb-2 row p-0 admin_header">
        <div class="d-flex p-0 admin_title">
          <div class="text-truncate-mess header-font font-weight-bold">
            著者管理
          </div>
        </div>
        <div class="row-input admin_center">
          <input
            class="form-control w-100 m-0"
            placeholder="検索"
            v-model="keywords"
            @keydown.enter.prevent="getListAuthors(1)"
            ref="refInput"
          />
        </div>
        <div class="tex-left text-md-right text-sm-right admin_right">
          <button
            v-on:click="createAuthor()"
            class="button-create-page"
            v-if="canCreate"
          >
            著者作成
          </button>
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <Tables :items="listAuthorData.data" :fields="fields" :itemsPerPage="10">
        <template v-slot:page_content="{ item }">
          <td v-html="item.page_content"></td>
        </template>
        <template v-slot:id="{ index }">
          <td>{{ listAuthorData.current_page * limit - limit + index + 1 }}</td>
        </template>
        <template v-slot:name="{ item }">
          <td>
            <div>{{ item.admin_name }}</div>
            <div>
              {{ item.name }}
            </div>
          </td>
        </template>
        <template v-slot:updated_at="{ item }">
          <td>
            {{
              new Date(item.updated_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 mb-0 pl-2 pr-1 d-flex">
            <!-- <router-link
              style="color: black"
              :to="{
                name: $route.params.shopId ? 'editAuthor' : 'editAuthor domain',
                params: {
                  shopId: shop_id,
                  id: item.id,
                },
                query: {
                  no: listAuthor.current_page * limit - limit + index + 1,
                },
              }"
            > -->
            <CButton
              class="mx-1 text-nowrap btn-other"
              color="primary"
              square
              variant="outline"
              v-on:click="editAuthor(item.id)"
              size="sm"
              v-b-modal.modal-comfirm
            >
              編集
            </CButton>
            <!-- </router-link> -->
            <CButton
              class="mx-1 text-nowrap btn-del"
              color="danger"
              square
              v-on:click="showConfirm(item.id)"
              variant="outline"
              size="sm"
              v-b-modal.delete-modal-page
            >
              削除
            </CButton>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listAuthorData.total"
      >
        <p class="font-weight-bold">
          {{ listAuthorData.total }}件中{{ listAuthorData.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listAuthorData.last_page"
      >
        <CPagination
          v-if="listAuthorData.total"
          :activePage.sync="page"
          :pages="listAuthorData.last_page"
          align="center"
        />
        <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div> -->
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-page" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            @click="removeAuthor(dataModal)"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-page')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "ListAuthor",
  components: {
    Tables,
  },
  data() {
    return {
      fields: tableFields.AUTHOR,
      listChecked: [],
      dataModal: "",
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      limit: 50,
      page: 1,
      // shop_id: null,
      dataAuthor: {
        shop_id: null,
        id: null,
      },
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      canCreate: false,
      keywords: null,
      listAuthorData: [],
    };
  },
  async created() {
    const extConstant = this.$route.params.shopId
      ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
      : "_" + Constants.DOMAIN;
    const userType = localStorage.getItem(
      Constants.USER_TYPE_ADMIN + extConstant
    );
    if (
      userType == Constants.USER_TYPE_DEFAULT.cdea ||
      userType == Constants.USER_TYPE_DEFAULT.owner
    )
      this.canCreate = true;
    await this.getListAuthors(1);
  },
  computed: {
    ...mapGetters(["listAuthor", "message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listAuthor() {
      this.listAuthorData = this.listAuthor;
      this.page = this.listAuthorData.current_page;
    },
    page() {
      this.getListAuthors(this.page);
    },
  },
  methods: {
    ...mapActions({ getListAuthor: "getListAuthor" }),
    ...mapActions({ getListAuthorAdmin: "getListAuthorAdmin" }),
    ...mapActions({ deleteAuthor: "deleteAuthor" }),
    createAuthor() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "CreateAuthor"
          : "CreateAuthor domain",
      });
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    async getListAuthors(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          keywords: this.keywords,
          limit: this.limit,
        },
      };
      await this.getListAuthorAdmin(formData);
      this.$refs.refInput.blur();
    },
    async removeAuthor(id) {
      this.dataAuthor.id = id;
      this.dataAuthor.shop_id = this.shop_id;
      const dataReturn = await this.$store.dispatch(
        "deleteAuthor",
        this.dataAuthor
      );
      if (dataReturn.success) {
        this.$toasted.success(dataReturn.message);
        this.$bvModal.hide("delete-modal-page");
        this.getListAuthors(1);
      } else {
        this.$toasted.error(dataReturn.message);
      }
    },
    editAuthor(id) {
      const shopId = this.$route.params.shopId;
      if (shopId) {
        this.$router.push({
          name: "editAuthor",
          params: { id: id, shopId: this.shop_id },
        });
      } else {
        this.$router.push({
          name: "editAuthor domain",
          params: { id: id },
        });
      }
    },
    changeLimitPage() {
      this.getListUsers(1);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
    unfocus() {
      this.$refs.refInput.blur();
    },
  },
};
</script>
